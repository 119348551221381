import React,{useEffect} from "react";
import bgpic from "../assests/slide40.png";
import img1 from "../assests/Howarewediff1.png";
import img2 from "../assests/Howarewediff2.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/howdifmerg.png";
import mpic from '../assests/howdiff1.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Howarewediff = ({onYearChange}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("why small");
      } else if (event.key === 'ArrowLeft') {
        onYearChange("2024");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>

    <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2024")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("why small")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>

      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div className="slide1heading " style={{ marginLeft: "-18px",fontSize:"55px",marginTop:"30px" }}>
                  <span>
                    How are we
                    <br />
                    different?
                  </span>
                </div>
              </div>

              <div className="mt-4 slide5listitems ">
                <ul>
                  <li>
                    Pure <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>focus on micro businesses building</span> the new India.
                  </li>
                  <li><span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>Highest product to price ratio</span> in the market.</li>
                  <li>
                    This is often undervalued, but we have the<span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>best workforce at
                    AltF.</span> 
                    <br />
                    Everyone is aligned towards our vision- to make lives better
                    for micro businesses.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none d-flex flex-column justify-content-center mbpt ">
        <div className="d-flex flex-row justify-content-center align-items-center pt-5 mb-3 ">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5 pt-2" >
            How are we
                    <br />
                    different?
            </h2>
          </div>
        </div>

     

        <div className="mbbggg mt-3">
        <div className=" slide4listitems  pt-0 ">
        <ul>
                  <li>
                    Pure <span style={{backgroundColor:"#D36161",color:"white",marginRight:"3px"}}>focus on micro businesses building</span> the new India.
                  </li>
                  <li><span style={{backgroundColor:"#D36161",color:"white",marginRight:"3px"}}>Highest product to price ratio</span> in the market.</li>
                  <li>
                    This is often undervalued, but we have the<span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>best workforce at
                    AltF.</span> 
                    <br />
                    Everyone is aligned towards our vision- to make lives better
                    for micro businesses.
                  </li>
                </ul>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center " >
            <div
              className="d-flex flex-column justify-content-center "
              style={{ width: "70vw" }}
            >
              <div>
                <img src={img1} alt="img" className="howwediff1" />
              </div>

              <div className="d-flex flex-row justify-content-end">
                <img src={img2} alt="img" className="howwediff2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howarewediff;
