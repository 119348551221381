import React, { useEffect } from "react";
import bgpic from "../assests/slide1.png";
import img1 from "../assests/slide31.png";
import img2 from "../assests/slide32.png";
import mpic from '../assests/slidem3.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide3 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2017");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2014");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2014")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2017")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
        
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" />
                <div className="slide1heading" style={{ marginTop: "60px" }}>
                <h2>2016</h2>
                  <span>
                    The Birth of AltF <br />
                    Coworking
                  </span>
                </div>
              </div>

              <div className="mt-4 slide1para">
                <p>
                  Convinced the landlord to let us sell the space and
                  <span
                    style={{
                      backgroundColor: "#D36161",
                      marginLeft: "4px",
                      color: "white",
                    }}
                  >
                    transformed the entire building into a fully occupied
                    coworking space
                  </span>{" "}
                  within months, leading to OYO's exit. We replicated the
                  'basement model' at another property and saw the same success.
                </p>
                <p>
                  This minimal amount of success was enough for both of us to
                  quit our jobs instantly. We were going to make a dent.
                </p>

                <h2 className="kuch pt-3">AltF Coworking was born in 2016.</h2>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain",height:"85vh" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none  moblievbox d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-1 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" >
            <h4 className="mt-4">
              The Birth of AltF <br />
              Coworking
            </h4>
          </div>
        </div>

       

        <div className="mbbggg">
          <p className="p-4 pb-0 text-center " style={{ fontSize: "18px" }}>
            Convinced the landlord to let us sell the space and
            <span
              style={{
                backgroundColor: "#D36161",
                marginLeft: "4px",
                color: "white",
              }}
            >
              transformed the entire building into a fully occupied coworking
              space
            </span>{" "}
            within months, leading to OYO's exit. We replicated the 'basement
            model' at another property and saw the same success. <br /> This
            minimal amount of success was enough for both of us to quit our jobs
            instantly. We were going to make a dent.
          </p>

          <h4
            className="kuchmb"
            style={{ textAlign: "center", fontSize: "20px",marginBottom:"20px" }}
          >
            AltF Coworking was born in 2016.
          </h4>


          <div className=" d-flex flex-row justify-content-center align-items-center ">
          <div
            className="d-flex flex-column justify-content-center align-items-center pb-3"
            style={{ width: "100vw" }}
         
          >
            <img src={img2} alt="img" className="slide2img4" />
           
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Slide3;
