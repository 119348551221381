import React, { useState, useEffect } from "react";
import "./timeline.css";
import Dropdown from "./dropdown";
import Sidebar from "./sidebar";
import { IoMdGlobe } from "react-icons/io";


const Timeline = ({ selectedYear, onYearChange }) => {
  const years = [
    { year: "2010" },
    { year: "2014" },
    { year: "2016" },
    { year: "2017" },
    { year: "2018" },
    { year: "2019" },
    { year: "2020" },
    { year: "2021" },
    { year: "2022" },
    { year: "2023" },
    { year: "2024" }, 
  ];

  useEffect(() => {
    const mobile_menu = document.querySelector(".mobile-menu");
    const mobile_trigger = document.querySelector(".mobile-menu__trigger");

    let initialPoint, finalPoint;

    const handleTouchStart = (event) => {
      initialPoint = event.changedTouches[0];
    };

    const handleTouchEnd = (event) => {
      finalPoint = event.changedTouches[0];
      let xAbs = Math.abs(initialPoint.pageX - finalPoint.pageX);
      let yAbs = Math.abs(initialPoint.pageY - finalPoint.pageY);

      if (xAbs > 120 || yAbs > 120) {
        if (xAbs > yAbs) {
          if (finalPoint.pageX < initialPoint.pageX) {
            // SWIPE LEFT
            mobile_menu.classList.remove("mobile-menu_open");
          } else {
            // SWIPE RIGHT
            mobile_menu.classList.add("mobile-menu_open");
          }
        }
      }
    };

    const handleClick = (event) => {
      const target = event.target.closest(".mobile-menu__trigger");
      if (target && target === mobile_trigger) {
        mobile_menu.classList.toggle("mobile-menu_open");
      } else if (
        event.target !== mobile_trigger &&
        event.target !== mobile_menu
      ) {
        if (mobile_menu.classList.contains("mobile-menu_open")) {
          mobile_menu.classList.remove("mobile-menu_open");
        }
      }
    };

    mobile_menu.querySelectorAll("a").forEach((element) => {
      element.addEventListener("click", (event) => {
        const anchor_href = event.currentTarget.getAttribute("href");
        if (anchor_href.charAt(0) === "#") {
          event.preventDefault();
          if (anchor_href.length > 1) {
            const scroll_to_node = document.querySelector(
              event.currentTarget.hash
            );
            if (scroll_to_node) {
              SmoothScrollTo(scroll_to_node);
            }
          }
        }
      });
    });

    const SmoothScrollTo = (element) => {
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("click", handleClick);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div>
      <div className="d-none d-lg-block pt-3">
        <ul className="yearsbox d-flex justify-content-around p-1 mb-0">
        <a href="https://www.altfcoworking.com" className="d-flex flex-column justify-content-center webbtn" target="_blank">
          <li className="websitebtn">Web-<IoMdGlobe style={{fontSize:"20px"}}/></li>
          </a>
       
          {years.map((item, index) => (
            <li
              key={index}
              className={`d-flex pointerrr ${
                selectedYear === item.year ? "activebg" : ""
              }`}
              onClick={() => onYearChange(item.year)}
            >
              <div
                className={`circlee ${
                  selectedYear === item.year ? "active" : ""
                }`}
              ></div>
              <span
                className={`years ${
                  selectedYear === item.year ? "selectedyear" : ""
                }`}
              >
                {item.year}
              </span>
            </li>
          ))} 

          <div style={{width:"33px"}} >
        <Dropdown onYearChange={onYearChange} />
        </div>
        </ul>

      </div>

      <div className="d-block d-lg-none">
      <div class="mobile-menu-trigger-wrapper">
            <div class="mobile-menu__trigger">
              <span></span>
              {selectedYear.length === 4 ? selectedYear : "Timeline"}
            </div>
          </div>
        <nav className="mobile-menu">
          

          <ul>
            {years.map((item, index) => (
              <li key={index} onClick={() => onYearChange(item.year)}>
                <span
                  className={`circlee ${
                    selectedYear === item.year ? "active" : ""
                  }`}
                ></span>
                {item.year}
              </li>
            ))}
          </ul>
        </nav>

        <Dropdown onYearChange={onYearChange} />
      </div>
    </div>
  );
};

export default Timeline;
