import { useState, useEffect, useRef } from "react";
import { TiThMenu } from "react-icons/ti";
import { CiMenuBurger } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";

function Dropdown({ onYearChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const dropdownRef = useRef(null);
  const mobileDropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const toggleMobileDropdown = () => setIsOpenMobile((prev) => !prev);

  const handleOptionClick = (selectedValue) => {
    onYearChange(selectedValue);
    setIsOpen(false);
    setIsOpenMobile(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setIsOpenMobile(false);
      }
    };

    if (isOpen || isOpenMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, isOpenMobile]);

  return (
    <div>
      {/* Desktop Dropdown */}
      <div className="custom-dropdown d-none d-lg-block" ref={dropdownRef}>
     
      <div className="dropdown-header" onClick={toggleDropdown}>
          <CiMenuBurger/>
        </div>
     
        
        {isOpen && (
          <ul className="dropdown-list open">
            <li onClick={() => handleOptionClick("how diff")}>How are we different</li>
            <li onClick={() => handleOptionClick("why small")}>Why small business</li>
            <li onClick={() => handleOptionClick("why choose")}>Why you should choose</li>
            {/* <li onClick={() => handleOptionClick("our word")} style={{ justifyContent: "start" }}>Our word</li> */}
            <li onClick={() => handleOptionClick("monologue")} style={{ justifyContent: "start" }}>Monologue</li>
          </ul>
        )}
      </div>

      {/* Mobile Dropdown */}
      <div className="d-flex flex-row justify-content-end mbdropdown d-block d-lg-none">
        <div className="custom-dropdown mt-2" ref={mobileDropdownRef}>
          <div className="dropdown-header d-flex flex-row justify-content-end" onClick={toggleMobileDropdown} >
            <span className="years">More <IoIosArrowDown/></span>
          </div>
          {isOpenMobile && (
            <ul className="dropdown-list open">
              <li onClick={() => handleOptionClick("how diff")}>How are we different</li>
              <li onClick={() => handleOptionClick("why small")}>Why small business</li>
              <li onClick={() => handleOptionClick("why choose")}>Why you should choose</li>
              {/* <li onClick={() => handleOptionClick("our word")} style={{ justifyContent: "start" }}>Our word</li> */}
              <li onClick={() => handleOptionClick("monologue")} style={{ justifyContent: "start" }}>Monologue</li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
