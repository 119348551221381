import React, { useEffect } from "react";
import bgpic from "../assests/slide1.png";
import img1 from "../assests/slide91.png";
import img2 from "../assests/slide92.png";
import mpic from '../assests/slidem9.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide9 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2023");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2021");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);




  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2021")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2023")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
         
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-30px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div
                  className="slide1heading"
                  style={{ marginLeft: "-60px", fontSize: "65px",marginTop:"20px" }}
                >
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2022  
                  </span><br/>
                  <span>
                  New Horizons
                  </span>
                </div>
              </div>

              <div className="mt-3 slide4listitems">
                <ul>
                  <li>
                    Gradually returned to expansion mode as stability improved,
                    <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>launching Global Business Park (Gurgaon), Statesman House
                    (Delhi), and Grovy Optiva (Noida).</span>
                  </li>
                  <li>
                    Planned further growth and, for the first time in 2022,
                    dedicated a resource specifically for expansion.
                  </li>
                  <li>Regained momentum, strengthened for future growth.</li>

                  <li>
                    Focused on enhancing processes, backend systems, and
                    building out key teams—especially marketing—for a <br/> faster,
                    more efficient approach.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none  d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" >
            <h2 className="mt-5">
            New Horizons
            </h2>
          </div>
        </div>

       

        <div className="mbbggg">
          <div className=" slide4listitems ">
          <ul>
                  <li>
                    Gradually returned to expansion mode as stability improved,
                    <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>launching Global Business Park (Gurgaon), Statesman House
                    (Delhi), and Grovy Optiva (Noida).</span>
                  </li>
                  <li>
                    Planned further growth and, for the first time in 2022,
                    dedicated a resource specifically for expansion.
                  </li>
                  <li>Regained momentum, strengthened for future growth.</li>

                  <li>
                    Focused on enhancing processes, backend systems, and
                    building out key teams—especially marketing—for a <br/> faster,
                    more efficient approach.
                  </li>
                </ul>
          </div>

          <div
          className=" d-flex flex-row justify-content-center align-items-center "
         
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center pb-3"
            
          >
           <img
              src={img2}
              alt="img"
              className="slide2img4 "
             
            />
           
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Slide9;
