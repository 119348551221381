import React, { useEffect } from "react";
import bgpic from "../assests/slide2year.png";
import img1 from "../assests/slide51.png";
import img2 from "../assests/slide52.png";
import img3 from "../assests/slide53.png";
import mpic from '../assests/slidem5.png'
import "./slide4.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide5 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2019");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2017");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2017")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2019")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div
            className="col-lg-6 d-flex flex-row justify-content-center align-items-center "
            style={{ height: "80%" }}
          >
            <div >
              <div
                className="d-flex flex-row justify-content-start align-items-center "
                
              >
                <img src={bgpic} alt="img" style={{ height: "190px" }} />
                <div className="slide1heading " style={{marginTop:"20px"}}>
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2018  
                  </span>
                  <br/>
                  <span style={{  fontSize: "50px",fontWeight:"400" }}>
                  Big Leaps in Space and Teams
                  </span>
                </div>
              </div>

              <div className="mt-4 slide4listitems ">
                <ul className="mt-3">
                  <li>
                  <span style={{backgroundColor:"#D36161",color:"white"}}>
                  Opened AltF Empire Square (550 seats) and AltF Success Tower
                  (750 seats).
                  </span>
                   
                  </li>
                  <li>
                    Expanded teams across Sales, Operations, and Accounts, while
                    Yogesh and I still led all departments.
                  </li>
                  <h4 className="kuchmb " style={{textAlign:"start",fontSize:"34px",marginTop:"50px"}}>
                  Heart and Hustle in Every Seat
          </h4>
                </ul>
              
              </div>

              
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain",height:"85vh" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none   d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center  allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5">Big Leaps in <br/> Space and Teams</h2>
          </div>
        </div>

        

        <div className="mbbggg">
          <div className="slide4listitems  pt-0 mt-4" >
          <ul style={{ fontSize: "18px" }}>
                  <li>
                  <span style={{backgroundColor:"#D36161",color:"white"}}>
                  Opened AltF Empire Square (550 seats) and AltF Success Tower
                  (750 seats).
                  </span>
                   
                  </li>
                  <li>
                    Expanded teams across Sales, Operations, and Accounts, while
                    Yogesh and I still led all departments.
                  </li>
                 
                </ul>
               
          </div>
          <h4 className="kuchmb " style={{textAlign:"center",fontSize:"20px"}}>
                  Heart and Hustle in Every Seat
          </h4>

          <div className=" d-flex flex-row justify-content-center align-items-start mt-3" >
            {/* <div className="mt-2 d-flex flex-column justify-content-center" >
              <img src={img3} alt="img" className="slide4pic4" />
            </div> */}

            <div className="pb-3 ">
             
              <img src={img1} alt="img" className="slide4pic3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide5;
