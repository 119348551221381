import React, { useEffect } from "react";
import bgpic from "../assests/slide2.png";
import img1 from "../assests/slide81.png";
import img2 from "../assests/slide82.png";
import img3 from "../assests/slide83.png";
import mpic from '../assests/slidem8.png'
import "./slide4.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide8 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2022");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2020");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2020")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2022")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                <img src={bgpic} alt="img" className="slide4bg" />
                <div className="slide1heading" style={{marginTop:"20px"}}>
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2021  
                  </span>
                  <br/>
                  <span style={{ marginLeft: "10px", fontSize: "51px" }}>
                    Weathering the Storm
                  </span>
                </div>
              </div>

              <div className="mt-4 slide4listitems">
                <ul>
                  <li>
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                      }}
                    >
                      Rebuilt occupancy,
                    </span>{" "}
                    though at significantly lower prices, before facing the
                    impact of COVID’s deadliest 2nd wave.
                  </li>
                  <li>
                    Revenue dropped again to 50%, but the broader impact made
                    business difficult to prioritise. Unlike the previous year,
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                      }}
                    >
                      clients returned faster,
                    </span>{" "}
                    allowing us to gradually rebuild towards our 2019 standing.
                  </li>
                  <h4
                    className="kuchmb mt-5"
                    style={{ textAlign: "start", fontSize: "30px" }}
                  >
                    AltF was a dream that refused to die.
                  </h4>
                </ul>
              </div>

             
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none   d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5">Weathering the Storm</h2>
          </div>
        </div>


        <div className="mbbggg mt-3">
          <div className=" slide4listitems" >
            <ul>
              <li>
                <span
                  style={{
                    backgroundColor: "#D36161",
                    
                    color: "white",
                  }}
                >
                  Rebuilt occupancy,
                </span>{" "}
                though at significantly lower prices, before facing the impact
                of COVID’s deadliest 2nd wave.
              </li>
              <li>
                Revenue dropped again to 50%, but the broader impact made
                business difficult to prioritise. Unlike the previous year,
                <span
                  style={{
                    backgroundColor: "#D36161",
                    marginLeft: "4px",
                    color: "white",
                  }}
                >
                  clients returned faster,
                </span>{" "}
                allowing us to gradually rebuild towards our 2019 standing.
              </li>
            </ul>
          </div>
          <h4
            className="kuchmb m-2"
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            AltF was a dream that refused to die.
          </h4>

          <div
          className="picbox4 d-flex flex-row justify-content-center align-items-start  pt-3"
        >
          {/* <div className="mt-2">
            <img src={img1} alt="img" className="slide4pic4" />
          </div> */}
          <div className=" ">
            <img src={img3} alt="img" className="slide8mbpic" />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Slide8;
