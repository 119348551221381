import React,{useEffect} from "react";
import bgpic from "../assests/slide2.png";
import img1 from "../assests/slide21.png";
import img2 from "../assests/slide22.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/slide2mobile2.png";
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Ourword = ({onYearChange}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("monologue");
      } else if (event.key === 'ArrowLeft') {
        onYearChange("why choose");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
    <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("why choose")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("monologue")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>
      <div className="">
        <div
          className="d-flex flex-row justify-content-center align-items-center ourwordheight"
          
        >
         <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
         <div className="wordwidth " >
            
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              
            >
              <img src={bgpic} alt="img" className="slide4bg" />
              <div className="slide1heading d-none d-lg-block" style={{ marginLeft: "-60px" }}>
                <span>Our Word</span>
              </div>
              <div className="slide1heading d-lg-none" style={{ marginLeft: "-60px",marginTop:"0px", }}>
                <h1>Our Word</h1>
              </div>
            </div>

            <div className="mt-4 slide1para">
              <p>
                This isn’t just a story about our battles;<br/> It’s a testament to
                the spirit that refuses to break,<br/>No matter how heavy the
                burden.<br/> We never had the luxury of giving up.<br/> We pushed
                forward, not because it was easy,<br/> but because we had no other
                choice.<br/> The people we serve, our clients, are reflections of
                who we are<br/> they’re fighters, dreamers, and believers in a
                better tomorrow.
              </p>
            </div>
          
        </div>
         </div>

          <div className="testred d-flex flex-column justify-content-center align-items-center d-none d-lg-block">
            {/* <div
              className="d-flex flex-column justify-content-around gap-3"
              style={{ height: "100%", width: "100%" }}
            >
              <div
                className="d-flex flex-row justify-content-end"
                style={{ marginRight: "-60px" }}
              >
                <img src={img2} alt="img" className="slide2img2" />
              </div>

              <div>
                <img src={img1} alt="img" className="slide2img1" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default Ourword;
