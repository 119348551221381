import React, { useEffect } from "react";
import bgpic from "../assests/slide1.png";
import img1 from "../assests/whysmall1.png";
import img2 from "../assests/whysmall2.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/slide2mobile2.png";
import mpic from "../assests/smallbis.png";
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Whysmallbusinnes = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("why choose");
      } else if (event.key === "ArrowLeft") {
        onYearChange("how diff");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("how diff")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("why choose")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="d-flex flex-row justify-content-center align-items-center containerheight">
          <div className="col-lg-8 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div
                  className="slide1heading "
                  style={{ marginLeft: "-25px", fontSize: "38px" }}
                >
                  <span>
                    Why do small
                    <br />
                    businesses choose us?
                  </span>
                </div>
              </div>

              <div className="mt-1 slide5listitems">
                <ul>
                  <li>
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                        marginRight: "3px",
                      }}
                    >
                      We understand them.
                    </span>{" "}
                    When their life becomes their work,
                    <br /> we become part of that life.
                  </li>
                  <li>
                    We don't lie. Our wifi slows down every now and then, and
                    our air conditioners either cool our spaces too much or too
                    little from time to time.{" "}
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                        marginRight: "3px",
                      }}
                    >
                      We fix everything, but we don’t lie.
                    </span>
                  </li>
                  <li>
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                        marginRight: "3px",
                      }}
                    >
                      We have the best on-ground support staff
                    </span>
                    who go out of their way to help a client.
                  </li>

                  <li>
                    We have{" "}
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                        marginRight: "3px",
                      }}
                    >
                      the best product to price ratio in our segment.
                    </span>{" "}
                    You get an office you’ve dreamed of, without breaking the
                    bank. We are a no-frills coworking. We don’t promise
                    anything we can’t deliver.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 d-flex flex-row justify-content-end align-items-end containerheight"
            data-aos="zoom-in"
          >
            <div className="d-flex flex-column justify-content-start align-items-end containerheight pt-5">
              <img
                src={mpic}
                alt="img"
                style={{ maxWidth: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none  d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-5 mb-2 mt-2">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" >
            <h2 className="mt-5">
              Why do small
              <br />
              businesses choose us?
            </h2>
          </div>
        </div>

        <div className="mbbggg mt-4">
          <div className="mt-4 slide4listitems  pt-0">
            <ul>
              <li>
                <span
                  style={{
                    backgroundColor: "#D36161",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  We understand them.
                </span>{" "}
                When their life becomes their work,
                <br /> we become part of that life.
              </li>
              <li>
                We don't lie. Our wifi slows down every now and then, and our
                air conditioners either cool our spaces too much or too little
                from time to time.{" "}
                <span
                  style={{
                    backgroundColor: "#D36161",
                    marginLeft: "4px",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  We fix everything, but we don’t lie.
                </span>
              </li>
              <li>
                <span
                  style={{
                    backgroundColor: "#D36161",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  We have the best on-ground support staff
                </span>
                who go out of their way to help a client.
              </li>

              <li>
                We have{" "}
                <span
                  style={{
                    backgroundColor: "#D36161",
                    marginLeft: "4px",
                    color: "white",
                    marginRight: "3px",
                  }}
                >
                  the best product to price ratio in our segment.
                </span>{" "}
                You get an office you’ve dreamed of, without breaking the bank.
                We are a no-frills coworking. We don’t promise anything we can’t
                deliver.
              </li>
            </ul>
          </div>

          <div className=" d-flex flex-row justify-content-center align-items-center mt-4">
            <div
              className="d-flex flex-column justify-content-center pb-3"
              style={{ width: "50vw" }} 
            >
              <img src={img2} alt="img" className="whysmallpic" />
              {/* <img src={img1} alt="img" className="slide2img3 ms-auto"  style={{marginTop:"20px"}}/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whysmallbusinnes;
