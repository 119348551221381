import React,{useEffect} from "react";
import "./welcomeslide.css";
import logo from "../assests/welcome.png";
import { MdArrowBackIosNew } from "react-icons/md";
import mpic from '../assests/intro1.png';
import mpicmb from '../assests/intromobile.png';
import { MdArrowForwardIos } from "react-icons/md";

const Welcomslide = ({onYearChange}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("welcome2");
      }  else if (event.key === 'ArrowLeft') {
        onYearChange("monologue");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div className="d-flex flex-row justify-content-center aligh-items-center containerheight" >
     

     <div className='d-none d-lg-block slide1bg ' style={{width:"100%"}}>
       <div className="col-lg-6 d-flex flex-column justify-content-end align-items-end" data-aos="zoom-in"  style={{width:"100%",height:"100%"}}>
           <img src={mpic} alt="img"  style={{  maxWidth: "45%",objectFit:"contain",marginRight:"60px" }}/>
       </div>
       </div>
  
       <div className="d-flex flex-column justify-content-start align-items-center d-lg-none mbpt silde1mgbg" style={{height:"100vh"}}>
      <div className="col-lg-10 d-flex flex-row justify-content-center align-items-center" >
           <img src={mpicmb} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain",height:"290px" }}/>
       </div>
      </div>
      
       <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
      <div onClick={() => onYearChange("monologue")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("welcome2")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>
     

    </div>
  );
};

export default Welcomslide;
