import React,{useEffect} from 'react';
import img from '../assests/welcomeslide2.png';
import blacklog from '../assests/black logo.png';
import line from '../assests/Line 11.png';
import mpic from '../assests/introtextpix.png';
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import mpicmb from '../assests/intromobile2.png';

const Welcomeslide2 = ({onYearChange}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("2010");
      } else if (event.key === 'ArrowLeft') {
        onYearChange("welcome");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div className="d-flex flex-row justify-content-center aligh-items-center containerheight" >
       
       <div className='d-none d-lg-block slide2bg' style={{height:"99%",width:"100%"}}>
       <div className="col-lg-6 d-flex flex-row justify-content-centet align-items-center " data-aos="zoom-in"  style={{height:"99%",width:"100%"}}>
           <img src={mpic} alt="img" className="containerheight " style={{  maxWidth: "100%",objectFit:"contain",height:"80%",marginLeft:"40px" }}/>
       </div>
       </div>
  
      <div className="d-flex flex-column justify-content-end align-items-center d-lg-none" style={{height:"100vh"}}>
      <div className="col-lg-10 d-flex flex-row justify-content-center align-items-center" >
           <img src={mpicmb} alt="img" className="containerheight" style={{  maxWidth: "100%" }}/>
       </div>
      </div>

        <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("welcome")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("2010")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>
    </div>
  )
}

export default Welcomeslide2