import React, { useEffect } from "react";
import bgpic from "../assests/slide40.png";
import img1 from "../assests/slide101.png";
import img2 from "../assests/slide102.png";
import mpic from '../assests/slidem10.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide10 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2024");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2022");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2022")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2024")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
         
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center" >
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div
                  className="slide1heading "
                  style={{ marginLeft: "-15px", fontSize: "55px",marginTop:"20px" }}
                >
                <span style={{ fontSize: "2rem",fontWeight:"500" }}>
                 2023 
                  </span>
                  <br/>
                  <span>Fueling Growth</span>
                </div>
              </div>

              <div className="mt-4 slide4listitems">
              <ul>
              <li>Adopted a faster-paced approach this year.</li>
              <li>
              <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}>Raised ₹1.8 Cr (while at ₹21 Cr ARR)</span>
                 from close associates to
                support a major space launch.
              </li>
              <li>
                Expanded the corporate team, bringing HR, Design and Projects
                functions in-house.
              </li>

              <li>
                Launched three of <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}> AltF’s largest properties: MPD (Gurgaon),
                Sector 142 (Noida), and Okhla (Delhi)</span>
              </li>
            </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none   d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5 pt-2">Fueling Growth</h2>
          </div>
        </div>

       
        <div className="mbbggg">
          <div className="mt-2 slide4listitems">
          <ul>
              <li>Adopted a faster-paced approach this year.</li>
              <li>
              <span style={{backgroundColor:"#D36161",color:"white"}}>Raised ₹1.8 Cr (while at ₹21 Cr ARR)</span>
                 from close associates to
                support a major space launch.
              </li>
              <li>
                Expanded the corporate team, bringing HR, Design and Projects
                functions in-house.
              </li>

              <li>
                Launched three of <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white"}}> AltF’s largest properties: MPD (Gurgaon),
                Sector 142 (Noida), and Okhla (Delhi)</span>
              </li>
            </ul>
          </div>


          <div
          className=" d-flex flex-row justify-content-center align-items-center "
          
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center pb-3"
            
          >
           <img
              src={img2}
              alt="img"
              className="slide2img4 "
             
            />
           
          </div>
        </div>

        </div>
      </div>
    </div>
  );
};

export default Slide10;
