import React, { useEffect } from "react";
import bgpic from "../assests/slide40.png";
import img1 from "../assests/whychooseus1.png";
import img2 from "../assests/whychooseus2.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/slide2mobile2.png";
import mpic from '../assests/whyshould.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Whychooseus = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("monologue");
      } else if (event.key === "ArrowLeft") {
        onYearChange("why small");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("why small")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>
        <div onClick={() => onYearChange("monologue")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center" >
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-30px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div
                  className="slide1heading "
                  style={{ marginLeft: "-10px", fontSize: "55px",marginTop:"40px" }}
                >
                  <span>
                    Why should
                    <br />
                    you choose us?
                  </span>
                </div>
              </div>

              <div className="mt-4 slide5listitems col-lg-11">
                <ul>
                  <li>
                  <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>Bootstrapped to 24 Cr ARR</span>
                   with 0 capital.</li>
                  <li>
                    Raised 1.8 Cr to grow to <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>45 Cr ARR</span>  with signed expansion to
                    boost revenue upto 80 Cr ARR.
                  </li>
                  <li><span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>Profitable every year,</span> with a current 10% MoM EBITDA.</li>

                  <li><span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>Asset & Liability</span> Light Model</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none moblievbox d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h2 className="mt-5 pt-2">
            Why should
                    <br />
                    you choose us?
            </h2>
          </div>
        </div>

       

        <div className="mbbggg">
          <div className="mt-4 slide4listitems   pt-0">
          <ul>
                  <li>
                  <span style={{backgroundColor:"#D36161",color:"white",marginRight:"3px"}}>Bootstrapped to 24 Cr ARR</span>
                   with 0 capital.</li>
                  <li>
                    Raised 1.8 Cr to grow to <span style={{backgroundColor:"#D36161",marginLeft:"4px",color:"white",marginRight:"3px"}}>45 Cr ARR</span>  with signed expansion to
                    boost revenue upto 80 Cr ARR.
                  </li>
                  <li><span style={{backgroundColor:"#D36161",color:"white",marginRight:"3px"}}>Profitable every year,</span> with a current 10% MoM EBITDA.</li>

                  <li><span style={{backgroundColor:"#D36161",color:"white",marginRight:"3px"}}>Asset & Liability</span> Light Model</li>
                </ul>
          </div>


          <div className=" d-flex flex-row justify-content-center align-items-center mt-1" >
          <div
            className="d-flex flex-column justify-content-around pb-3"
            style={{ width: "70vw" }}
          >
            <img
              src={img2}
              alt="img"
              className="slide2img4"
              style={{ height: "150px",width:"150px" }}
            />
            <img
              src={img1}
              alt="img"
              className="slide2img3 ms-auto"
              style={{ height: "130px",width:"120px" }}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Whychooseus;
