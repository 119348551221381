import React, { useEffect } from "react";
import bgpic from "../assests/slide2year.png";
import img1 from "../assests/slide21.png";
import img2 from "../assests/slide22.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/slide2mobile2.png";
import mpic from '../assests/slidem2.png'
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide2 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2016");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2010");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2010")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>
        <div onClick={() => onYearChange("2016")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center containerheight"
          
        >
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-40px" }}
              >
                <img src={bgpic} alt="img" style={{ height: "190px" }} />
                <div className="slide1heading" style={{ marginTop: "-40px" }}>
                <h2>2014</h2>
                  <span style={{ marginLeft: "14px", fontSize: "54px" }}>
                    Against the Grain
                  </span>
                </div>
              </div>

              <div className="mt-4 slide1para">
                <p>
                  We took Gurgaon MNC jobs to save money but always aimed to
                  create our own flow rather than just go with it.
                </p>
                <p>
                  Our founders' mindset led us to an OYO guest house basement in
                  2014, where we saw India's coworking demand and a huge
                  opportunity to provide an{" "}
                  <span style={{ color: "#D36161", fontWeight: "500" }}>
                    Alt
                  </span>
                  <span style={{ fontWeight: "500" }}>ernative</span>{" "}
                  <span style={{ color: "#D36161", fontWeight: "500" }}>F</span>
                  <span style={{ fontWeight: "500" }}>or</span> traditional
                  office spaces.
                </p>
              </div>
              <h2 className="kuch mt-5">
                Yes, that’s where the name
                came from.
              </h2>
            </div>
          </div>

          <div className="col-lg-5 d-flex flex-row justify-content-center align-items-center" data-aos="zoom-in">
           <img src={mpic} alt="img" className="containerheight" style={{  maxWidth: "100%",objectFit:"contain",height:"85vh" }}/>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none pt-5  d-flex flex-column justify-content-center mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center  mt-2">
          <div className="mbslide2heading text-center d-flex flex-column mt-4">
            <h2 className="mt-5">Against the Grain</h2>
          </div>
        </div>

        <div className="mbbggg mt-3">
          <p className="p-4 text-center " style={{ fontSize: "18px" }}>
            We took Gurgaon MNC jobs to save money but always aimed to create
            our own flow rather than just go with it. <br />
            <br /> Our founders' mindset led us to an OYO coworking basement in
            2014, where we saw India's coworking demand and a huge opportunity
            to provide an{" "}
            <span style={{ color: "#D36161", fontWeight: "500" }}>Alt</span>
            <span style={{ fontWeight: "500" }}>ernative</span>{" "}
            <span style={{ color: "#D36161", fontWeight: "500" }}>F</span>
            <span style={{ fontWeight: "500" }}>or</span> traditional office
            spaces.
          </p>


          <div
          className=" d-flex flex-row justify-content-center align-items-center"
         
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center pt-3"
            style={{ width: "100vw" }}
          >
           
            <img src={img1} alt="img" className="slide2img4" />
          </div>
        </div>
        </div>

       

     
      </div>
    </div>
  );
};

export default Slide2;
