import React, { useEffect } from "react";
import bgpic from "../assests/slide40.png";
import img1 from "../assests/slide41.png";
import img2 from "../assests/slide42.png";
import img3 from "../assests/slide43.png";
import mpic from "../assests/slidem4.png";
import "./slide4.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const Slide3 = ({ onYearChange }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        onYearChange("2018");
      } else if (event.key === "ArrowLeft") {
        onYearChange("2016");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onYearChange]);
  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("2016")}>
          <MdArrowBackIosNew style={{ fontSize: "30px" }} />
        </div>

        <div onClick={() => onYearChange("2018")}>
          <MdArrowForwardIos style={{ fontSize: "30px" }} />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="d-flex flex-row justify-content-center align-items-center containerheight">
          <div className="col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{ marginLeft: "-30px" }}
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div className="slide1heading" style={{marginTop:"20px"}}>
                <span style={{ marginLeft: "60px", fontSize: "2rem",fontWeight:"500" }}>
                 2017  
                  </span>
                  <br/>
                  <span style={{ marginLeft: "50px", fontSize: "55px" }}>
                 
                    Scaling Smartly
                  </span>
                </div>
              </div>

              <div
                className="mt-4 slide4listitems"
                style={{ marginLeft: "-30px" }}
              >
                <ul>
                  <li>
                    Launched AltF Plaza Mall with 50 seats, using second- hand
                    materials. Designed the layout on paper.
                  </li>
                  <li>
                    <span
                      style={{
                        backgroundColor: "#D36161",
                        marginLeft: "4px",
                        color: "white",
                      }}
                    >
                      Expanded to AltF Sector 44 (150 seats)
                    </span>{" "}
                    and AltF Global Foyer (250 seats), without having any money
                    to give as deposit to the landlord.
                  </li>
                  <li>
                    Built a small team, trained, and developed processes from
                    scratch.
                  </li>
                </ul>
              </div>

              <h4
                className="kuchmb mt-5"
                style={{ textAlign: "start", fontSize: "30px" }}
              >
                Total emails in our inbox for the year-146.
              </h4>
            </div>
          </div>

          <div
            className="col-lg-5 d-flex flex-row justify-content-center align-items-center"
            data-aos="zoom-in"
          >
            <img
              src={mpic}
              alt="img"
              className="containerheight"
              style={{ Width: "100%", objectFit: "contain", height: "85vh" }}
            />
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none  moblievbox d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center  allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}
>
            <h2 className="mt-5 pt-2">Scaling Smartly</h2>
          </div>
        </div>

        <div className="mbbggg">
          <div className=" slide4listitems">
            <ul>
              <li>
                Launched AltF Plaza Mall with 50 seats, using second- hand
                materials. Designed the layout on paper.
              </li>
              <li>
                <span
                  style={{
                    backgroundColor: "#D36161",

                    color: "white",
                  }}
                >
                  Expanded to AltF Sector 44 (150 seats)
                </span>{" "}
                and AltF Global Foyer (250 seats), without having any money to
                give as deposit to the landlord.
              </li>
              <li>
                Built a small team, trained, and developed processes from
                scratch.
              </li>
            </ul>
          </div>

          <h4
            className="kuchmb p-2"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Total emails in our inbox for the year-146.
          </h4>

          <div
            className=" d-flex flex-row justify-content-center align-items-center"
          >
           
            <div>
              <img
                src={img1}
                alt="img"
                className="slide4pic3"
                style={{ height: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide3;
