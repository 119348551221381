import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import TimelineNavigation from './components/timelinenavigation';

function App() {
  return (
    <div className='bgwhole'>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<TimelineNavigation />}/>

      </Routes>
    </BrowserRouter>
    </div>
    
  );
}

export default App;
