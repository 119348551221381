import React,{useEffect} from "react";
import bgpic from "../assests/slide40.png";
import img1 from "../assests/slide21.png";
import img2 from "../assests/slide22.png";
import img3 from "../assests/slide2mobile1.png";
import img4 from "../assests/slide2mobile2.png";
import monolog from '../assests/Monologue.png';
import mpic from '../assests/Monologue.png';
import "./slide2.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const TheHurdle = ({onYearChange}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        onYearChange("welcome");
      } else if (event.key === 'ArrowLeft') {
        onYearChange("why choose");
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onYearChange]);

  return (
    <div>
    <div className="d-flex flex-row justify-content-between align-items-center arrowsintro">
        <div onClick={() => onYearChange("why choose")}>
        <MdArrowBackIosNew style={{fontSize:"30px"}}/>
        </div>

        <div onClick={() => onYearChange("welcome")}>
        <MdArrowForwardIos style={{fontSize:"30px"}}/>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div
          className="d-flex flex-row justify-content-center align-items-center "
          style={{ height: "85vh" }}
        >
          <div className="col-lg-8 d-flex flex-row justify-content-center align-items-center">
            <div>
              <div
                className="d-flex flex-row justify-content-start align-items-center"
                
              >
                <img src={bgpic} alt="img" className="slide4bg" />
                <div className="slide1heading " style={{ marginLeft: "-25px",fontSize:"55px" }}>
                  <span>Our Word</span>
                </div>
              </div>

              <div className="mt-4 slide5listitems monolog">
                <p>
                  Every drop of sweat, every sleepless night, every moment of
                  doubt—<br/> they all fade away when you’re driven by a vision
                  that’s bigger than yourself.<br/><br/> It’s about holding on to that
                  dream, even when everything around you tells you to let go. <br/>
                  We’re here to help others reach their dreams, just as we’re
                  clawing our way towards ours.<br/> The only way we know how to do
                  this is by offering a space where they can find the strength
                  to keep going. We see our own journey in theirs, and it
                  inspires us everyday. They deserve the best.<br/><br/> We’re not for
                  everyone, and not everyone is for us.<br/> But for those who
                  understand this journey, we are here for them.<br/><br/> -Sarthak &
                  Yogesh
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 d-flex flex-row justify-content-end align-items-end containerheight" data-aos="zoom-in">
          <div className="d-flex flex-column justify-content-start align-items-end containerheight pt-5">
          <img src={mpic} alt="img"  style={{  maxWidth: "100%",objectFit:"contain" }}/>
          </div>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none   d-flex flex-column justify-content-around mbpt">
        <div className="d-flex flex-row justify-content-center align-items-center pt-3 allpagemargintop">
          <div className="mbslide2heading text-center d-flex flex-column mbslide3heading" style={{ backgroundImage: `url(${bgpic})` }}>
            <h1 className="mt-5 pt-2" >
            Our Word
            </h1>
          </div>
        </div>

        

        <div className="mbbggg">
        <div className="  mbhowediff p-4">
        <p>
                  Every drop of sweat, every sleepless night, every moment of
                  doubt—<br/> they all fade away when you’re driven by a vision
                  that’s bigger than yourself.<br/><br/> It’s about holding on to that
                  dream, even when everything around you tells you to let go. <br/>
                  We’re here to help others reach their dreams, just as we’re
                  clawing our way towards ours.<br/> The only way we know how to do
                  this is by offering a space where they can find the strength
                  to keep going. We see our own journey in theirs, and it
                  inspires us everyday. They deserve the best.<br/><br/> We’re not for
                  everyone, and not everyone is for us.<br/> But for those who
                  understand this journey, we are here for them.<br/><br/> -Sarthak &
                  Yogesh
                </p>
              </div>

              <div className=" d-flex flex-column justify-content-center align-items-center pb-3">
           
           <div className="">
             <img src={monolog} alt="img" className=" " style={{height:"220px"}}/>
           </div>

        
       </div>
        </div>
      </div>
    </div>
  );
};

export default TheHurdle;
